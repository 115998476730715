import swellAlbumArt from '../images/HNY-WLSN-Swell-album-artwork.jpg'
import lakamhaAlbumArt from '../images/Lakamha-album-art.jpg'
import thursdayTheTwelthAlbumArt from '../images/BlacKostume-Thursday-the-Twelfth-album-artwork.jpg'
import winterAlbumArt from '../images/Winter-album-art.jpg'
import abandonedBeamshipsAlbumArt from '../images/Abandoned-Beamships-album-art.jpg'
import toxicWashAlbumArt from '../images/Toxic-Wash-album-art.jpg'
import theAcidFolkAlbumArt from '../images/The-Acid-Folk-album-art.jpg'
import cf2AlbumArt from '../images/CF2-album-art.jpg'

export const hnyWlsnAlbums = [
    {
        name: 'Swell',
        image: swellAlbumArt,
        imageLink: 'https://open.spotify.com/album/4tQJaI3ytzjQL84eMD25AC?si=YNXCG2ReQOGm-V6c0KBzMg',
        links: [
            {
                url: 'https://open.spotify.com/album/4tQJaI3ytzjQL84eMD25AC?si=YNXCG2ReQOGm-V6c0KBzMg',
                text: 'STREAM'
            },
            {
                url: 'https://hnywlsn.bandcamp.com/releases',
                text: 'BUY'
            },
        ],
    },
    {
        name: 'Lakamha',
        image: lakamhaAlbumArt,
        imageLink: 'https://hnywlsn.bandcamp.com/album/lakamha',
        links: [
            {
                url: 'https://hnywlsn.bandcamp.com/album/lakamha',
                text: 'STREAM'
            },
            {
                url: 'https://hnywlsn.bandcamp.com/album/lakamha',
                text: 'BUY'
            },
        ],
    },
]

export const blacKostumeAlbums = [
    {
        name: 'Thursday the Twelfth',
        image: thursdayTheTwelthAlbumArt,
        imageLink: 'https://open.spotify.com/album/1JeFdAiKlvmb6KwIrIE05y',
        links: [
            {
                url: 'https://open.spotify.com/album/1JeFdAiKlvmb6KwIrIE05y',
                text: 'STREAM'
            },
            {
                url: 'https://blackostume.bandcamp.com/album/thursday-the-twelfth',
                text: 'BUY'
            },
        ],
    },
]

export const darlingCoilsAlbums = [
    {
        name: 'Winter',
        image: winterAlbumArt,
        imageLink: 'https://open.spotify.com/album/53QLmVJOrDDaOZHBCNVHim',
        links: [
            {
                url: 'https://open.spotify.com/album/53QLmVJOrDDaOZHBCNVHim',
                text: 'STREAM'
            },
            {
                url: 'https://darlingcoils.bandcamp.com/',
                text: 'BUY'
            },
        ],
    },
    {
        name: 'Abandoned Beamships',
        image: abandonedBeamshipsAlbumArt,
        imageLink: 'https://open.spotify.com/album/0rr3PHQ509sV8XNdIkCUdg',
        links: [
            {
                url: 'https://open.spotify.com/album/0rr3PHQ509sV8XNdIkCUdg',
                text: 'STREAM'
            },
            {
                url: 'https://darlingcoils.bandcamp.com/',
                text: 'BUY'
            },
        ],
    },
    {
        name: 'Toxic Wash',
        image: toxicWashAlbumArt,
        imageLink: 'https://open.spotify.com/album/5XZG39LomzDZhB2UCm6k0E',
        links: [
            {
                url: 'https://open.spotify.com/album/5XZG39LomzDZhB2UCm6k0E',
                text: 'STREAM'
            },
            {
                url: 'https://darlingcoils.bandcamp.com/album/toxic-wash',
                text: 'BUY'
            },
        ],
    },
    {
        name: 'The Acid Folk',
        image: theAcidFolkAlbumArt,
        imageLink: 'https://open.spotify.com/album/79OFtPlKvLf4MnmxunxfHh',
        links: [
            {
                url: 'https://open.spotify.com/album/79OFtPlKvLf4MnmxunxfHh',
                text: 'STREAM'
            },
            {
                url: 'https://darlingcoils.bandcamp.com/album/the-acid-folk',
                text: 'BUY'
            },
        ],
    },
]

export const counterfitAlbums = [
    {
        name: 'CF2',
        image: cf2AlbumArt,
        imageLink: 'https://soundcloud.com/counter-fit/sets/cf2',
        links: [
            {
                url: 'https://soundcloud.com/counter-fit/sets/cf2',
                text: 'STREAM'
            },
        ],
    },
]

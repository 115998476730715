import React from "react"
import SEO from "../../components/seo"
import Layout from "../../components/Layout"
import TextLink from "../../components/TextLink"
import Album from '../../components/Album'
import hnyWlsnPhoto from '../../images/HNY-WLSN.jpg'
import { hnyWlsnAlbums } from '../../data/albums'
import './style.css'

const HnyWlsn = () => (
  <Layout>
    <SEO title="HNY WLSN" />
    <div className="artist-page">
      <div className="artist-page__bio">
        <div>
          <h1>HNY WLSN</h1>
          <h2>Charlotte, NC</h2>
        </div>
        <p>DIY synth, funk, and wave music out of Charlotte.</p>
        <div className="artist-page__bio-links">
          <TextLink href='https://open.spotify.com/artist/4ETDDOPGq8RXskWQNqdv5R'>Spotify</TextLink>
          <TextLink href='https://soundcloud.com/lakamha'>Soundcloud</TextLink>
          <TextLink href='https://hnywlsn.bandcamp.com/releases'>Bandcamp</TextLink>
          <TextLink href='https://www.instagram.com/hny_wlsn/'>Instagram</TextLink>
          <TextLink href='https://twitter.com/hny_wlsn'>Twitter</TextLink>
        </div>
      </div>
      <div className="artist-page__photo">
        <img src={hnyWlsnPhoto} alt="HNY WLSN" />
      </div>
      <div className="artist-page__albums">
        <h3>Albums</h3>
        <div className="artist-page__albums-list">
          {
            hnyWlsnAlbums.map((album, index) => <Album key={index} album={album} />)
          }
        </div>
      </div>
    </div>
  </Layout>
)

export default HnyWlsn

import React from 'react'
import TextLink from '../TextLink'
import './style.css'

const Album = ({
    album: {
        name,
        image,
        imageLink,
        links
    }
}) => (
    <div className="album">
        <a href={imageLink} target="_blank" rel="noreferrer">
            <img src={image} alt={name} />
        </a>
        <div className="album__details">
            <div className="album__name">
                <TextLink href={imageLink}>
                    {name}
                </TextLink>
            </div>
            <div className="album__links">
                {
                    links?.map((link, index) => (
                        <TextLink key={index} href={link.url}>
                            {link.text}
                        </TextLink>
                    ))
                }
            </div>
        </div>
    </div>
)

export default Album
